import * as React from "react"
import { Link } from "gatsby"
import Nav2 from "../components/nav2"
import Footer from "../components/footer"

const NotFoundPage = () => {
  return (
    <div pageTitle="404">
      <Nav2 />
      <main className="nomal-page">
        <h1>お探しのページは見つかりません。</h1>
        <p>
          We couldn’t find what you were looking for.
          <br />
          <br />
          <br />
          <br />
          <Link to="/" className="underline">ホームに戻る</Link>.<br/>
        </p>
      </main>
      <Footer />
    </div>
  )
}

export default NotFoundPage
